<template>
  <div class="d-flex flex-column">
    <input
      id="video"
      type="file"
      accept="video/*"
      capture="environment"
      @change="handleFileInput"
      style="display: none"
    />
    <v-btn
      :disabled="disabled"
      :loading="loading"
      rounded
      color="#067b26"
      class="button white--text"
      @click="take"
      v-if="!evidenceSelected.content_url"
    >
      Capturar Vídeo
    </v-btn>
  </div>
</template>
<script>
import PedidoService from "@/services/pedidos.service";
import GlobalStore from "@/store/modules/global";
import { router } from "@/router";

export default {
  name: "CapturePhotoMobile",
  props: {
    evidenceSelected: {
      type: Object,
    },
    geolocation: {
      type: Object
    }
  },
  data: () => ({
    photo: "",
    loading: false,
    disabled: false,
  }),
  methods: {
    handleFileInput(event) {
      this.disabled = true;
      this.loading = true;
      const file = event.target.files[0];
      if (file) {
        const {
          key,
          kind: { name },
        } = this.evidenceSelected;

        const keySolicitation = this.$route.params.key;
        const reader = new FileReader();

        reader.onload = function (event) {
          const arrayBuffer = event.target.result;
          const blob = new Blob([arrayBuffer], { type: "video/webm" });
          var formData = new FormData();

          const latitude = this.geolocation.latitude ? this.geolocation.latitude : ''
          const longitude = this.geolocation.longitude ? this.geolocation.longitude : ''

          formData.append('latitude', latitude)
          formData.append('longitude', longitude)
          formData.append("content", blob, name + ".webm");

          PedidoService.updateEvidences(key, formData).subscribe(
            (res) => {
              window.location.reload();
            },
            (erro) => {
              GlobalStore.setSnackBar({
                show: true,
                type: "error",
                message: "Não foi possível enviar, tente novamente",
              });
            }
          );
        };

        reader.readAsArrayBuffer(file);
      }
    },
    take() {
      document.getElementById("video").click();
    },
  },
};
</script>
